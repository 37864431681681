// // //*for live api use with code uncomment============>>>>/*
export const environment = {
  production: true,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/',
  imageurl:'https://api.fareboutique.com/uploads/airlines/',
  XApiKey:'1VIJPS1644324705625'
};

// //*for devapi use uncomment============>>>>*/
// export const environment = {
//   production: true,
//   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
//   url:'https://devpatnerapi.fareboutique.com/',
//   imageurl:'https://devapi.fareboutique.com/uploads/airlines/',
//   XApiKey:'1FMQKB1639407126571'
// };


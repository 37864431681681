import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CommonService, SharedserviceService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // showLeftBar:boolean=false;
  // user_id:any;
  // role_id:any;
  // user:any;

  // constructor(private router:Router) { 
  //   this.user = JSON.parse(localStorage.getItem("user"));
  //   
//console.log("user ::::::ASD:::::",this.user)
  //   if(this.user != null){ 
  //     this.user_id = this.user.id;
  //     this.role_id = this.user.role_id;
  //   }
  // }
  

  // ngOnInit() {
  // }
  // logout() {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  //   this.router.navigateByUrl('/')
  // }

  userdata:any;
  user:any;
  user_type:any;
  name:any;
  id:any;
  formEmail: FormGroup;
  showlogin:boolean=true;
  submitted: boolean = false;
  parseuser:any;
  getData: any;
  isGetOwnerData: any;
  constructor(private router: Router,public commonService:CommonService,private fb: FormBuilder,public sharedService: SharedserviceService, private toastr: ToastrManager) {
    
  }
  
  ngOnInit() { 
    this.contactDetail();
    this.user=JSON.parse(localStorage.getItem("user"));
   
    this.formEmail = this.fb.group({
      name: ['',Validators.required],
      email: ['',Validators.required],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      mobile_no: ['',Validators.required],
    
      send_to_email:['']
    });
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      
//console.log("usertype",this.user_type);
      this.showlogin=false;
      this.name = this.user.name;
    }
    
//console.log("user name  ::::",this.name)
  } 
  get enq() { return this.formEmail.controls; }


  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  }  
  contactDetail() {
    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        console.log("::::::::::::::",this.getData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.getData));
        setTimeout(() => {
          this.userdata= JSON.parse(localStorage.getItem("userAgency"));
          this.isGetOwnerData= JSON.parse(sessionStorage.getItem("ownerData"));    
        }, 200);
        
      } else {

      }
    });
  }
  ENQ() {
    $('#Enq_us').modal('show');
} 
closeENQ() {
  $('#Enq_us').modal('hide');
  
} 
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  } 
  enquiryForm(){
    this.submitted = true;
    if (this.formEmail.invalid) { 
      return;
    }else{
    var requestData ={
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject": this.formEmail.value.subject,
      "message": this.formEmail.value.message,
      "company_name": "test",
      "mobile_no": this.formEmail.value.mobile_no.toString(),
     
      "send_to_email":this.getData.email
    }
    this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#Enq_us').modal('hide');
        this.submitted = false;
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    },err=>{
    
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }
  
  } 
 
}
